import { PublicConfig } from '../../models/environment.public';
import { commonEnvVars, stagingEnv } from 'src/common/environments/environment.public.common';

const config: PublicConfig = {
    appId: 'l7xxafdbee7bb20545c7a374c8a476772ac2',
    appziSrc: `https://w.appzi.io/w.js?token=kWt9x`,
    brand: 'Keeprite',
    brandPath: 'icp',
    publicBaseUrl: 'https://staging-keeprite.hvacpartners.com',
    siteCode: 809,
    ...stagingEnv
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const environment: any = { ...commonEnvVars(config) };
