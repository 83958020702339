<div class='hvac-content-header-container' *ngIf="isLandingPage || (!(dataResolverService.resolving$ | async) && !(errorService.errorStatus$ | async))">
    <div class="hvac-content-header" [ngClass]="{'hvac-content-header-color': colorTheme, 'empty-jumpbar': (emptyJumpbar$ | async), 'landing-page-header': isLandingPage }">
        <div *ngIf="colorTheme" class="hvac-content-header-hero-pattern-mask">
            <utc-svg class="hvac-content-header-hero-pattern" src="assets/images/home-pattern.svg"></utc-svg>
        </div>
        <div class="hvac-content-header-info-container">
            <div class='hvac-content-title-container'>
                <div class='hvac-content-breadcrumb-container'>
                    <span class='hvac-content-crumb-container' *ngFor="let crumb of breadCrumbsToShow; first as isFirst" ><span *ngIf="!isFirst" class='hvac-breadcrumb-divider'>/</span><a [routerLink]="[crumb.url]" class='hvac-subtext'>{{crumb.title}}</a></span>
                </div>
                <div [ngClass]="{ 'hvac-header-title-container': true, 'hvac-header-title-container-right-side-content': rightSideContent}">
                    <h2 class='hvac-content-header-title' [innerHTML]="titleToShow | richText"></h2>
                    <ng-content #headerLeftSide></ng-content>
                </div>
                <div class='hvac-content-header-subtitle hvac-p' *ngIf="subtitle">
                    <p class="hvac-subtitle-rich-text" [ngClass]="{'hvac-subtitle-rich-text-white': colorTheme}" [innerHTML]="subtitle | richText"></p>
                </div>
            </div>
        </div>
    </div>
</div>
