<section class='hvac-accordion-new'
    [class.hvac-accordion-new-open]="open"
    [class.hvac-accordion-new-close]="!open"
    [class.hvac-accordion-new-disabled]="disabled">
    <ng-content select="hvac-accordion-header"></ng-content>

    <div class="hvac-accordion-body-container" [attr.aria-hidden]="!open">
        <ng-content select="hvac-accordion-body"></ng-content>
    </div>

    <button type="button" class="hvac-button-icon hvac-button-has-icon" [disabled]="disabled" (click)="onClick()" aria-label="accordion button" [attr.aria-expanded]="open">
        <utc-svg 
        [ngClass]="{ 'hvac-svg' : theme === 'default', 'hvac-svg-light': theme === 'light' }" 
        src="assets/icons/chevron-down.svg"></utc-svg>
    </button>
</section>
