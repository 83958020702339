import { NavMenu } from 'common/services/navigation-content.service';

const BASE_TITLE = 'HVACpartners';

const APP_PAGES_TITLES: { [key: string]: string } = {
    '': 'My Dashboard',
    'login': BASE_TITLE,
    'sign-out': BASE_TITLE,
    'logout': BASE_TITLE,
    'error': BASE_TITLE,
    'callback': BASE_TITLE,
    'change-password': 'Change Password',
    'authenticating': BASE_TITLE,
    'create-account-information': BASE_TITLE,
    'create-account-company-lookup': BASE_TITLE,
    'create-account-email-validate': BASE_TITLE,
    'search': 'Search',
    'home': BASE_TITLE,
    'product': 'Products',
    'marketing': 'Marketing',
    'learning': 'Learning',
    'inbox': 'Inbox',
    'distributor-locator': 'Distributor Locator',
    'manage-dealer-locator': 'Dealer Locator',
    'manage-counties': 'Manage Counties',
    'trade-partners': 'TradePartners',
    'users': 'User-Details'
};

// convert first letter of each word in a string to uppercase
export function stringToPascalCase(string: string) {
    return string.replace(/(\b[a-z](?!\s))/g, (x) => x.toUpperCase());
}

export function formatFrameTitle(url: string) {
    let titleText = '';
    let titleTextWithSuf = url.split('/').pop()?.replace(/\?\w+=.*/, '');
    if (titleTextWithSuf && titleTextWithSuf.includes('#')) {
        titleTextWithSuf = titleTextWithSuf.split('#')[0];
    }
    titleText = titleTextWithSuf ? titleTextWithSuf.split('.')[0] : '';

    if (titleText && titleText.includes('-')) {
        titleText = titleText.split('-').join(' ');
        titleText = stringToPascalCase(titleText);
    }

    return titleText ? titleText.replace(/([A-Z][a-z])/g, ' $1').trimLeft() : BASE_TITLE;
}

export function searchRecentActivityTitle(url: string) {
    const title = url.split('/').pop();
    const titleArr = title ? title.split('?q=') : [];
    let type = titleArr[0] ? `${titleArr[0]} ` : '';
    const typeArr = type.split('');
    typeArr[0] = typeArr[0].toUpperCase();
    type = typeArr.join('');
    const query = titleArr[1] ? ` for: ${titleArr[1]}` : '';

    return `${type}Search${query}`;
}

export function createTitle(url: string, navUrl?: NavMenu) {
    if (navUrl && navUrl.title) {
        return navUrl.title;
    }

    const baseMatch = url.match(/^\/?([^?/]*)/);
    if (baseMatch) {
        if (typeof baseMatch[1] !== 'undefined' && baseMatch[1] !== null) {
            const basePath = baseMatch[1].toLowerCase();
            if (APP_PAGES_TITLES[basePath]) {
                return APP_PAGES_TITLES[basePath];
            }
        }
    }

    return formatFrameTitle(url);
}
